/** @jsx jsx */
import { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { jsx } from "theme-ui";
import slugify from "slugify";

import Products from "../templates/products";

const PRODUCTS_QUERY = graphql`
  query ProductsQuery {
    wpPage(slug: { eq: "produkty" }) {
      seo {
        metaDesc
        title
      }
      title
    }
    allWpProduct(
      sort: { fields: slug, order: ASC }
      filter: { status: { eq: "publish" } }
    ) {
      edges {
        node {
          name
          slug
          description
          image {
            # sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          productCategories {
            nodes {
              name
            }
          }
        }
      }
    }
    allWpProductCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`;

const comparePosts = (a, b) => {
  if (Number(a.slug.split("-")[1]) < Number(b.slug.split("-")[1])) {
    return -1;
  } else return 0;
};

const ProductsPage = props => {
  const [searchResult, setSearchResult] = useState([]);
  const { wpPage, allWpProduct, allWpProductCategory } = useStaticQuery(
    PRODUCTS_QUERY
  );
  const searchParams = new URLSearchParams(props.location.search);
  // console.log(searchResult);
  let results =
    (searchResult.length && searchResult) ||
    allWpProduct?.edges?.map(x => x.node);

  // creates here duplicates
  // results = [...new Set(results.map(x => x.slug.split("-")[0]))].flatMap(x =>
  //   results.filter(y => y.slug.includes(x)).sort(comparePosts)
  // );

  if (props.location.search.length) {
    results = results.filter(
      y =>
        slugify(y.productCategories.nodes[0].name).toLowerCase() ===
        searchParams.get("kategoria")
    );
  }

  return (
    <Products
      wpPage={wpPage}
      results={results}
      setSearchResult={setSearchResult}
    />
  );
};

export default ProductsPage;
